.panel-wrapper {
  overflow: hidden;
}

.panel-wrapper-enter, .panel-wrapper-appear {
  max-height: 0;
  &.panel-wrapper-enter-active, &.panel-wrapper-appear-active {
    max-height: 127px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
  }
}

.panel-wrapper-leave {
  max-height: 127px;
  &.panel-wrapper-leave-active {
    max-height: 0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
  }
}

.glyphicon.glyphicon-remove {
  cursor: pointer;
}