.react-bs-table table th{
  white-space: normal !important;
}

.react-bs-table td {
  white-space: normal !important;
}

.button-inspect button {
  margin-left: 10px;
}

.error-report-btn {
  margin: 10px;

}

.react-bs-table {
  .tr-message-status-not-sent, .tr-message-status-not-delivered {
    td {
      background-color: rgba(218, 81, 69, 0.69);
    }
  }
  .tr-card-insurance-inactive {
    td {
      background-color: rgba(199, 217, 218, 0.69);
    }
  }

}
