.search-block input{
  display: block !important;
  margin-bottom: 10px;
}

.react-bs-container-body.recent-searches tbody tr:hover {
  cursor: pointer;
  background-color: azure;
}

.nav-tabs .nav-item {
  margin: 5px 5px;
}

input[type="checkbox"].myclassname {
  width: 14pt;
  height: 14pt;

}
