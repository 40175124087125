.tr-rejected-by-operator,
.tr-rejected-by-operator:hover td {
  background-color: rgba(218, 81, 69, 0.73);
}

.tr-approved-by-operator,
.tr-approved-by-operator:hover td {
  background-color: rgba(229, 231, 33, 0.69);
}

.tr-processed-by-abs,
.tr-processed-by-abs:hover td {
  background-color: rgba(50, 201, 99, 0.69);
}

.tr-processing-by-operator,
.tr-processing-by-operator:hover td {
  background-color: rgba(75, 193, 221, 0.69);
}

.react-bs-table-container {
  margin-bottom: 50px;
}

.insurance-requests-table-blocked,
.passport-requests-table-blocked,
.address-requests-table-blocked {
  .table {
    opacity: 0.6;
  }
  .page-link {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
}

.requests-table-blocked {
  .table {
    opacity: 0.6;
  }
  .page-link,
  #pageDropDown {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
}

.filter-header {
  .control-label {
    margin: 5px;
  }
  .form-control {
    margin: 5px;
    width: 80%;
  }
  .btn {
    margin: 5px;
  }
  [class*='col-'] {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
}

.address-request-btn {
  margin: 5px 10px;
}


.refresh_btn {
  margin-left: 10px;
}
