.modal-title {
  text-align: center;
}

.passport-modal {
  width: 95%;
}

.insurance-modal {
  width: 90%;
}

.reject-insurance-modal {
  width: 50%;
}

.address-modal {
  width: 70%;
  input, textarea {
    width: 300px;
  }
}

.modal-content {
  width: 150%;

  .carousel,
  .item {
    height: 800px;
  }

  [class*="col-"] {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }

  .control-label {
    font-size: 70%;
  }

  .dropdown-menu {
    min-width: 100%;
  }

  .address-previous-text {
    ul {
      display: inline;
    }
    padding-left: 150px;
    width: 370px;
    font-size: 16px;
  }

  textarea {
    resize: vertical;
  }
}

.reason-header {
  text-align: center;
}

.pictures-error {
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}